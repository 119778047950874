import axios from "axios";

import {
  ACTION_STARTED,
  ACTION_ERROR,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
  AUTH_REGISTER_USER,
  AUTH_LOG_IN_USER,
  AUTH_LOG_OUT_USER,
  AUTH_FETCH_CURRENT_USER,
  AUTH_GOOGLE_LOG_IN,
  AUTH_COMPLETE_REGISTRATION_REQUIRED,
  AUTH_VERIFY_EMAIL_REQUIRED,
  AUTH_USER_EMAIL_REQUIRED,
  AUTH_ADD_USER_EMAIL,
  AUTH_VERIFY_EMAIL,
  AUTH_RESEND_VERIFY_EMAIL,
  AUTH_COMPLETE_REGISTRATION,
  AUTH_CHANGE_PASSWORD,
  AUTH_RESET_PASSWORD,
  AUTH_REQUEST_RESET_PASSWORD_CODE,
  AUTH_DISMISS_USERHOME_SUB_MESSAGE,
  PETS_FETCH_ALL,
  LITTERS_FETCH,
  AUTH_UPDATE_EMAIL_PREFERENCES,
  AUTH_UPDATE_LANGUAGE_PREFERENCE,
  AUTH_UPDATE_USER_WEIGHT_UNIT_PREFERENCE,
  AUTH_UPDATE_DASHBOARD_PREFERENCES,
  AUTH_FETCH_INFO_AND_UPDATES,
} from "./types";
import { catchBlockError, endAndResetAction } from "./functions";
import { updateIntl } from "react-intl-redux";
import { updateLocale } from "functions";
import messages from "messages";
import { AUTH_FETCH_CURRENT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";

export const registerUser =
  (values, setSubmitting, resetForm, history) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AUTH_REGISTER_USER });
      const res = await axios.post("/api/auth/signup", values);
      dispatch({
        type: ACTION_SUCCESS,
        payload: "registrationSuccess",
      });
      dispatch({ type: AUTH_LOG_IN_USER });
      localStorage.setItem("token", res.data.token);
      history.push("/userhome");
      dispatch({ type: ACTION_ENDED });
      dispatch({ type: ACTION_RESET });
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 3000);
    }
  };

export const addUserEmail = (values) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: AUTH_ADD_USER_EMAIL });
    await axios.post("/api/auth/add_user_email", values, {
      headers: { authorization: localStorage.getItem("token") },
    });
    dispatch({ type: AUTH_VERIFY_EMAIL_REQUIRED });
    return endAndResetAction(dispatch);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 2000);
  }
};

export const loginUser =
  (values, setSubmitting, history) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AUTH_LOG_IN_USER });
      const res = await axios.post("/api/auth/signin", values);
      dispatch({
        type: ACTION_SUCCESS,
        payload: "loginSuccess",
      });
      dispatch({ type: AUTH_LOG_IN_USER });
      localStorage.setItem("token", res.data.token);
      history.push("/userhome");
      endAndResetAction(dispatch);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const googleLogIn = (token, history) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: AUTH_GOOGLE_LOG_IN });
    const res = await axios.post("/api/auth/oauth/google", {
      id_token: token,
    });

    if (res.data.localEmailExists) {
      dispatch({
        type: ACTION_ERROR,
        payload: "localAccountAlreadyExistsGoogle",
      });
      dispatch({ type: ACTION_ENDED });
      return setTimeout(() => {
        dispatch({ type: AUTH_LOG_OUT_USER });
        history.push("/register");
        dispatch({ type: ACTION_RESET });
      }, 5000);
    }

    if (res.data.noEmailProvided) {
      dispatch({
        type: ACTION_ERROR,
        payload: "noEmailProvidedWithGoogleLogin",
      });
      dispatch({ type: ACTION_ENDED });
      return setTimeout(() => {
        dispatch({ type: AUTH_LOG_OUT_USER });
        history.push("/register");
        dispatch({ type: ACTION_RESET });
      }, 5000);
    }

    dispatch({
      type: ACTION_SUCCESS,
      payload: "googleLoginSuccess",
    });
    dispatch({ type: AUTH_LOG_IN_USER });
    localStorage.setItem("token", res.data.token);
    history.push("/userhome");
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_RESET });
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          dispatch({
            type: ACTION_ERROR,
            payload: "unauthorized",
          });
          break;
        case 422:
          dispatch({
            type: ACTION_ERROR,
            payload: "localAccountAlreadyExists",
          });
          break;
        default:
          dispatch({
            type: ACTION_ERROR,
            payload: "googleLoginError",
          });
          break;
      }
    }
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: AUTH_LOG_OUT_USER });
      history.push("/login");
      dispatch({ type: ACTION_RESET });
    }, 3000);
  }
};

export const fetchCurrentUser =
  (history, initialFetch, locale) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AUTH_FETCH_CURRENT_USER });
      const res = await axios.get("/api/current_user", {
        headers: { authorization: localStorage.getItem("token") },
        params: { initialFetch },
      });

      if (res.data.completeRegistrationRequired) {
        dispatch({ type: AUTH_COMPLETE_REGISTRATION_REQUIRED });
        return endAndResetAction(dispatch);
      }
      if (res.data.verifyEmailRequired) {
        dispatch({ type: AUTH_VERIFY_EMAIL_REQUIRED });
        return endAndResetAction(dispatch);
      }
      if (res.data.userEmailRequired) {
        dispatch({ type: AUTH_USER_EMAIL_REQUIRED });
        return endAndResetAction(dispatch);
      }
      if (initialFetch) {
        dispatch({
          type: AUTH_FETCH_CURRENT_USER,
          payload: res.data.initialFetchObject.user,
        });
        dispatch({
          type: PETS_FETCH_ALL,
          payload: res.data.initialFetchObject.pets,
        });
        dispatch({
          type: LITTERS_FETCH,
          payload: res.data.initialFetchObject.litters,
        });

        // SET LANGUAGE
        if (!res.data.initialFetchObject.user.locale) {
          return dispatch({ type: ACTION_RESET });
        }
        dispatch(
          updateIntl({
            locale: res.data.initialFetchObject.user.locale,
            messages,
          })
        );
        updateLocale(res.data.initialFetchObject.user.locale);
        return dispatch({ type: ACTION_RESET });
      }
      if (res.data.fetch) {
        dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
        return setTimeout(() => {
          dispatch({ type: ACTION_RESET });
        }, 2000);
      }
      dispatch({ type: ACTION_ENDED });
      dispatch({ type: ACTION_RESET });
    } catch (error) {
      dispatch({
        type: ACTION_ERROR,
        payload: "fetchUserProfile",
      });
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: AUTH_LOG_OUT_USER });
        history.push("/login");
        dispatch({ type: ACTION_RESET });
      }, 3000);
    }
  };

export const verifyEmail =
  ({ verifyEmailCode }, resetForm) =>
  async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AUTH_VERIFY_EMAIL });
      const res = await axios.post(
        "/api/auth/verify_email",
        { verifyEmailCode: verifyEmailCode },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      );
      dispatch({
        type: ACTION_SUCCESS,
        payload: "emailVerificationSuccess",
      });
      dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
      dispatch({ type: ACTION_ENDED });
      dispatch({ type: ACTION_RESET });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          dispatch({
            type: ACTION_ERROR,
            payload: "invalidVerifyEmailCode",
          });
        } else {
          dispatch({
            type: ACTION_ERROR,
            payload: "somethingWentWrong",
          });
        }
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 4000);
    }
  };

export const resendVerifyEmail = (locale) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: AUTH_RESEND_VERIFY_EMAIL });
    await axios.post(
      "/api/auth/verify_email/resend",
      { locale: locale },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    );
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_SUCCESS, payload: "verificationCodeSentSuccess" });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 2000);
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      });
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      });
    }
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 2000);
  }
};

export const completeRegistration = (values) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: AUTH_COMPLETE_REGISTRATION });
    const res = await axios.post("/api/auth/complete_registration", values, {
      headers: { authorization: localStorage.getItem("token") },
    });
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    dispatch({
      type: ACTION_SUCCESS,
      payload: "registrationSuccess",
    });
    dispatch({ type: ACTION_RESET });
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 2000);
  }
};

export const changePassword =
  (values, setSubmitting, resetForm, history) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AUTH_CHANGE_PASSWORD });
      await axios.post("/api/auth/change_password", values, {
        headers: { authorization: localStorage.getItem("token") },
      });
      dispatch({ type: ACTION_ENDED });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "passwordChangeSuccess",
      });
      dispatch({ type: AUTH_LOG_OUT_USER });
      history.push("/login");
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 4000);
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({
            type: ACTION_ERROR,
            payload: "changePasswordMismatch",
          });
        } else {
          dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
        }
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      resetForm();
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 4000);
    }
  };

export const requestResetPasswordCode =
  (values, setSubmitting, setNextStage) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: AUTH_REQUEST_RESET_PASSWORD_CODE,
      });
      await axios.post("/api/auth/request_reset_password_code", values);
      dispatch({ type: ACTION_ENDED });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "requestResetPasswordCodeSuccess",
      });
      setNextStage();
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 2000);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({
            type: ACTION_ERROR,
            payload: "emailNotFound",
          });
        } else {
          dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
        }
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 3000);
    }
  };

export const resetPassword =
  (values, setSubmitting, resetForm, history) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AUTH_RESET_PASSWORD });
      await axios.post("/api/auth/reset_password", values);
      dispatch({ type: ACTION_ENDED });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "passwordChangeSuccess",
      });
      dispatch({ type: AUTH_LOG_OUT_USER });
      history.push("/login");
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 4000);
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({
            type: ACTION_ERROR,
            payload: "invalidResetPasswordCode",
          });
        } else {
          dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
        }
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 3000);
    }
  };

export const logOutUser = () => async (dispatch) => {
  dispatch({ type: AUTH_LOG_OUT_USER });
};

export const dismissUserhomeSubMessage = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: AUTH_DISMISS_USERHOME_SUB_MESSAGE,
    });
    const res = await axios.get("/api/auth/dismiss_userhome_sub_message", {
      headers: { authorization: localStorage.getItem("token") },
    });
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_RESET });
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 2000);
  }
};

export const updateEmailPreferences = (values) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: AUTH_UPDATE_EMAIL_PREFERENCES,
    });
    const res = await axios.put("/api/auth/update_email_preferences", values, {
      headers: { authorization: localStorage.getItem("token") },
    });
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_RESET });
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 2000);
  }
};

export const updateLanguagePreference =
  (isAuthed, lang, providerView) => async (dispatch) => {
    const API = `/api/${
      providerView ? "provider" : "current_user"
    }/update_language_preference`;
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: AUTH_UPDATE_LANGUAGE_PREFERENCE,
      });
      if (isAuthed) {
        const res = await axios.put(
          API,
          { lang },
          {
            headers: {
              authorization: localStorage.getItem(
                providerView ? "tokenProvider" : "token"
              ),
            },
          }
        );
        if (providerView) {
          dispatch({
            type: AUTH_FETCH_CURRENT_PROVIDER,
            payload: res.data.provider,
          });
        } else {
          dispatch({
            type: AUTH_FETCH_CURRENT_USER,
            payload: res.data.user,
          });
        }
      }
      localStorage.setItem("languagePreference", lang);
      dispatch(updateIntl({ locale: lang, messages }));
      updateLocale(lang);
      dispatch({ type: ACTION_ENDED });
      dispatch({ type: ACTION_RESET });
    } catch (error) {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      });
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 2000);
    }
  };

export const updateWeightUnitPreference = (unit) => async (dispatch) => {
  const API = `/api/current_user/update_weight_unit_preference`;
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: AUTH_UPDATE_USER_WEIGHT_UNIT_PREFERENCE,
    });
    const res = await axios.put(
      API,
      { unit },
      {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      }
    );
    dispatch({
      type: AUTH_FETCH_CURRENT_USER,
      payload: res.data.user,
    });
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_RESET });
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 2000);
  }
};

export const updateDashboardPreferences = (values) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: AUTH_UPDATE_DASHBOARD_PREFERENCES,
    });
    const res = await axios.put(
      "/api/current_user/update_dashboard_preferences",
      values,
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    );
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_RESET });
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 2000);
  }
};

export const fetchInfoAndUpdates = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: AUTH_FETCH_INFO_AND_UPDATES,
    });
    const res = await axios.get("/api/info_and_updates/fetch", {
      headers: { authorization: localStorage.getItem("token") },
    });
    dispatch({
      type: AUTH_FETCH_INFO_AND_UPDATES,
      payload: res.data.infoAndUpdates,
    });
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_RESET });
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 2000);
  }
};

const authActions = {
  registerUser,
  loginUser,
  fetchCurrentUser,
  googleLogIn,
  verifyEmail,
  resendVerifyEmail,
  completeRegistration,
  changePassword,
  requestResetPasswordCode,
  resetPassword,
  logOutUser,
  dismissUserhomeSubMessage,
  updateEmailPreferences,
  addUserEmail,
  updateLanguagePreference,
  updateWeightUnitPreference,
  updateDashboardPreferences,
  fetchInfoAndUpdates,
};

export default authActions;
