import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { main } from "assets";

function BenefitsInfo({ handleModalClose, messages, locale, buttons }) {
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <section className="modal-card-body has-text-dark">
          <div className="has-text-right">
            <button
              className="delete is-right"
              aria-label="close"
              onClick={() => handleModalClose()}
            />
          </div>
          <div className="columns is-mobile is-2 is-variable is-paddingless">
            <div className="column"></div>
            <div className="column is-10 has-text-centered">
              <img src={main.benefitsPopup} alt="benefits_popup_image" />
              {messages.bodyText[locale].map((t, i) => (
                <div key={i} style={{ paddingBottom: 6 }}>
                  <p style={{ padding: 0 }}>{t}</p>
                </div>
              ))}
              <br />
              <Link
                to={"/userhome/user_account/subscription"}
                className="button is-link is-fullwidth"
                onClick={() => handleModalClose()}
              >
                <span className="icon">
                  <i className={buttons.subscribeNow.icon} />
                </span>
                <span>{buttons.subscribeNow[locale]}</span>
              </Link>
            </div>
            <div className="column"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.components.modals.benefitsMoreInfo,
    buttons: state.intl.messages.components.buttons,
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(BenefitsInfo);
