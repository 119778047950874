import Button from "components/elements/Button";
import Buttons from "components/elements/Buttons";
import React from "react";
import FeaturePromo from "./Feature_Promo";
import { MODALS_SHOW, MODALS_MORE_INFO, MODALS_HIDE } from "actions/types";

function AdsAndBenefits({
  logoUrl,
  imageUrl,
  description,
  offer,
  website,
  dispatch,
}) {
  function handleReadMoreClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_MORE_INFO,
      modalProps: {
        type: "benefits",
        handleModalClose: () => dispatch({ type: MODALS_HIDE }),
        children: (
          <ReadMore
            props={{ logoUrl, imageUrl, website, offer, description }}
          />
        ),
      },
    });
  }

  return (
    <div className="box" style={{ width: "100%" }}>
      <div style={{ height: 120 }}>
        <a rel="noopener noreferrer" href={website} target="_blank">
          <figure className="image is-inline-block">
            <img src={logoUrl} alt={"main_logo"} />
          </figure>
        </a>
      </div>
      <div className="card-content">
        <div>
          <FeaturePromo title={offer} />
        </div>
      </div>
      <Buttons className="is-centered">
        <Button
          group="common"
          name="readMore"
          onClick={() => handleReadMoreClick()}
        />
      </Buttons>
    </div>
  );
}

const ReadMore = ({
  props: { logoUrl, imageUrl, website, offer, description },
}) => {
  return (
    <div className="has-text-center">
      <div style={{ maxHeight: 100 }}>
        <a rel="noopener noreferrer" href={website} target="_blank">
          <figure className="image is-inline-block">
            <img src={logoUrl} alt={"main_logo"} />
          </figure>
        </a>
      </div>
      {imageUrl && (
        <a rel="noopener noreferrer" href={website} target="_blank">
          <figure className="image is-inline-block">
            <img src={imageUrl} alt={"main_image"} />
          </figure>
        </a>
      )}
      <div className="card-content">
        <FeaturePromo title={offer} />
      </div>
      <div className="card-content">
        {description}
        <br />
      </div>
      <Buttons className="is-centered">
        <Button
          group="common"
          name="visitWebsite"
          className="is-link"
          href
          url={website}
          withIcon
          iconRight
        />
      </Buttons>
    </div>
  );
};

export default AdsAndBenefits;
