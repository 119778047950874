import React, { useState } from "react";
import { connect } from "react-redux";
import { billing as actions } from "actions";
import {
  useStripe,
  useElements,
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { createOptions } from "./Card_Element";
import SummaryDetails from "../User_Account/Subscription/Summary_Details";
import StripeElementWrapper from "components/styled/Stripe_Element_Wrapper";
import PoweredByStripeLogo from "components/elements/Powered_By_Stripe";
import Intl from "components/Intl";

function SetupSub({
  user,
  messages,
  locale,
  createFirstTimeSubscription,
  createSubscriptionExistingCustomer,
  createCustomerAndSubscription,
}) {
  // STRIPE HOOK
  const stripe = useStripe();
  const elements = useElements();

  // USE STATE
  //
  //
  const [validation, setValidation] = useState({ error: true, message: "" });
  const [cardInputsCompleted, setCardInputComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCVC: false,
  });

  // FUNCTIONS
  //
  //
  function handleChange({ error, complete }, inputName) {
    if (!error) {
      setValidation({ error: false, message: "" });
    }
    if (error) {
      setCardInputComplete({ ...cardInputsCompleted, [inputName]: false });
      setValidation({ error: true, message: error.message });
    }
    if (!complete) {
      setCardInputComplete({ ...cardInputsCompleted, [inputName]: false });
    }
    if (complete) {
      setCardInputComplete({ ...cardInputsCompleted, [inputName]: true });
    }
  }

  async function submit(ev) {
    try {
      ev.preventDefault();

      const { paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(
          CardNumberElement,
          CardExpiryElement,
          CardCvcElement
        ),
        billing_details: {
          email: user.email,
        },
      });

      if (!user.stripeCustomerId) {
        return createCustomerAndSubscription(paymentMethod);
      }

      return createSubscriptionExistingCustomer(paymentMethod);
    } catch (error) {
      console.log("submit error", error);
    }
  }

  // MAIN RENDER
  //
  //
  return (
    <>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <div className="checkout notification" style={{ padding: 8 }}>
            <div className="columns is-2 is-variable is-flex-desktop is-mobile is-multiline">
              <div className="column is-6-desktop is-12-mobile is-flex-desktop">
                <div className="box" style={{ width: "100%" }}>
                  <div className="level is-mobile is-marginless">
                    <div className="level-left">
                      <p className="title is-5 has-text-dark">
                        {messages.labels.enterCardDetails[locale]}
                      </p>
                    </div>
                    <div className="level-right">
                      <PoweredByStripeLogo />
                    </div>
                  </div>
                  <label className="label is-small">
                    {messages.labels.cardNumber[locale]}
                    <StripeElementWrapper>
                      <CardNumberElement
                        onChange={(e) => handleChange(e, "cardNumber")}
                        options={createOptions()}
                      />
                    </StripeElementWrapper>
                  </label>
                  <label className="label is-small">
                    {messages.labels.cardExpiry[locale]}
                    <StripeElementWrapper>
                      <CardExpiryElement
                        onChange={(e) => handleChange(e, "cardExpiry")}
                        options={createOptions()}
                      />
                    </StripeElementWrapper>
                  </label>
                  <label className="label is-small">
                    {messages.labels.cardCVC[locale]}
                    <StripeElementWrapper>
                      <CardCvcElement
                        onChange={(e) => handleChange(e, "cardCVC")}
                        options={createOptions()}
                      />
                    </StripeElementWrapper>
                  </label>
                  {validation.error && (
                    <p className="is-danger help">{validation.message}</p>
                  )}
                </div>
              </div>
              <div className="column is-6-desktop is-12-mobile is-flex-desktop">
                <div className="box" style={{ width: "100%" }}>
                  <div className="buttons">
                    <button
                      className="button is-link is-fullwidth"
                      onClick={submit}
                      disabled={
                        validation.error ||
                        Object.keys(cardInputsCompleted).some(
                          (k) => !cardInputsCompleted[k]
                        )
                      }
                    >
                      <Intl
                        message
                        path={"components.buttons.stripe.setupSubscription"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ElementsConsumer>
      <SummaryDetails />
    </>
  );
}

export default connect(null, actions)(SetupSub);
