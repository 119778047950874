import axios from "axios";
import {
  AUTH_FETCH_CURRENT_USER,
  BILLING_CREATE_SUBSCRIPTION,
  BILLING_FETCH_BILLING_DATA,
  BILLING_UPDATE_CARD,
  BILLING_CANCEL_SUBSCRIPTION,
  BILLING_ACTION_REQUIRED,
  ACTION_STARTED,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
} from "./types";
import { catchBlockError } from "./functions";

export const finalizeSubscriptionAfterSCA = (onSuccess) => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_CREATE_SUBSCRIPTION });
  try {
    const res = await axios.get(
      "/api/billing/stripe/finalize_subscription_after_sca",
      { headers: { authorization: localStorage.getItem("token") } }
    );
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_SUCCESS, payload: "createSubscriptionSuccess" });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 1000);
    return onSuccess();
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const fetchBillingData = () => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_FETCH_BILLING_DATA });
  try {
    const res = await axios.get("/api/billing/stripe/subscription_data", {
      headers: { authorization: localStorage.getItem("token") },
    });
    dispatch({
      type: BILLING_FETCH_BILLING_DATA,
      payload: res.data,
    });
    dispatch({ type: ACTION_RESET });
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const cancelSubscription = () => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_CANCEL_SUBSCRIPTION });
  try {
    const res = await axios.get("/api/billing/stripe/cancel_subscription", {
      headers: { authorization: localStorage.getItem("token") },
    });
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_SUCCESS, payload: "cancelSubscriptionSuccess" });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 3000);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const setPaymentMethodRequired = () => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_ACTION_REQUIRED });
  try {
    const res = await axios.get(
      "/api/billing/stripe/set_payment_method_required",
      { headers: { authorization: localStorage.getItem("token") } }
    );

    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 1000);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const retrieveSetupIntent = () => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_ACTION_REQUIRED });
  try {
    const res = await axios.get("/api/billing/stripe/retrieve_setup_intent", {
      headers: { authorization: localStorage.getItem("token") },
    });

    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_RESET });
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const createSetupIntent = () => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_UPDATE_CARD });
  try {
    const res = await axios.get("/api/billing/stripe/create_setup_intent", {
      headers: { authorization: localStorage.getItem("token") },
    });

    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 1000);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const cancelSetupIntent = () => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_UPDATE_CARD });
  try {
    const res = await axios.get("/api/billing/stripe/cancel_setup_intent", {
      headers: { authorization: localStorage.getItem("token") },
    });
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 1000);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const finalizePaymentMethod =
  (intent, onSuccess) => async (dispatch) => {
    dispatch({ type: ACTION_STARTED, payload: BILLING_UPDATE_CARD });
    try {
      const res = await axios.post(
        "/api/billing/stripe/finalize_payment_method",
        { intent },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      );
      dispatch({ type: ACTION_SUCCESS, payload: "updateCardSuccess" });
      dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
      dispatch({ type: ACTION_ENDED });
      onSuccess();
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 1000);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const createCustomerAndSubscription =
  (paymentMethod) => async (dispatch) => {
    dispatch({ type: ACTION_STARTED, payload: BILLING_CREATE_SUBSCRIPTION });
    try {
      const res = await axios.post(
        "/api/billing/stripe/first_time_subscription",
        { paymentMethod },
        { headers: { authorization: localStorage.getItem("token") } }
      );

      if (!res.data.SCArequired) {
        dispatch({
          type: ACTION_SUCCESS,
          payload: "createSubscriptionSuccess",
        });
      }

      dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 1000);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const createSubscriptionExistingCustomer =
  (paymentMethod) => async (dispatch) => {
    dispatch({ type: ACTION_STARTED, payload: BILLING_CREATE_SUBSCRIPTION });
    try {
      const res = await axios.post(
        "/api/billing/stripe/create_subscription_existing_customer",
        { paymentMethod },
        { headers: { authorization: localStorage.getItem("token") } }
      );

      if (!res.data.SCArequired) {
        dispatch({
          type: ACTION_SUCCESS,
          payload: "createSubscriptionSuccess",
        });
      }

      dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 1000);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

const billingActions = {
  finalizeSubscriptionAfterSCA,
  fetchBillingData,
  cancelSubscription,
  setPaymentMethodRequired,
  retrieveSetupIntent,
  createSetupIntent,
  finalizePaymentMethod,
  cancelSetupIntent,
  // New Actions
  createCustomerAndSubscription,
  createSubscriptionExistingCustomer,
};

export default billingActions;
