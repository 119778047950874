import React, { useState } from "react";
import { connect } from "react-redux";
import { billing as actions } from "actions";
import { createOptions } from "./Card_Element";
import {
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import Message from "components/message";
import StripeElementWrapper from "components/styled/Stripe_Element_Wrapper";
import PoweredByStripeLogo from "components/elements/Powered_By_Stripe";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";
import Intl from "components/Intl";

function UpdateCard({
  user,
  messages,
  locale,
  createSetupIntent,
  finalizePaymentMethod,
  cancelSetupIntent,
  history,
}) {
  // STRIPE HOOK
  const stripe = useStripe();

  // USE STATE
  //
  //
  const [validation, setValidation] = useState({ error: true, message: "" });
  const [element, setElement] = useState(null);
  const [authFail, setAuthFail] = useState(false);
  const [cardInputsCompleted, setCardInputComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCVC: false,
  });

  // FUNCTIONS
  //
  //
  function handleChange({ error, complete }, inputName) {
    if (!error) {
      setValidation({ error: false, message: "" });
    }
    if (error) {
      setCardInputComplete({ ...cardInputsCompleted, [inputName]: false });
      setValidation({ error: true, message: error.message });
    }
    if (!complete) {
      setCardInputComplete({ ...cardInputsCompleted, [inputName]: false });
    }
    if (complete) {
      setCardInputComplete({ ...cardInputsCompleted, [inputName]: true });
    }
  }

  function handleReady(ele) {
    setElement(ele);
  }

  async function submit(ev) {
    setAuthFail(false);
    try {
      const res = await stripe.handleCardSetup(
        user.stripeClientSecret,
        element
      );
      return finalizePaymentMethod(res.setupIntent, () =>
        history.push("/userhome/user_account/subscription")
      );
    } catch (error) {
      setAuthFail(true);
      await cancelSetupIntent();
    }
  }

  async function handleCancel() {
    await cancelSetupIntent();
  }

  async function handleUpdateCardButton() {
    await createSetupIntent();
  }

  // MAIN RENDER
  //
  //
  if (!user.stripeRequiresCardSetup)
    return (
      <Buttons>
        <Button
          link
          to="/userhome"
          className="is-fullwidth is-success"
          group="stripe"
          name="startUsing"
        />
        <Button
          className="is-fullwidth"
          group="stripe"
          name="updateCard"
          onClick={handleUpdateCardButton}
        />
      </Buttons>
    );

  return (
    <>
      {authFail && (
        <Message default color="is-danger">
          <Intl message path="components.messages.errors.STRIPE1" />
        </Message>
      )}
      <div className="checkout notification" style={{ padding: 8 }}>
        <div className="columns is-2 is-variable is-flex-desktop is-mobile is-multiline">
          <div className="column is-6-desktop is-12-mobile is-flex-desktop">
            <div className="box" style={{ width: "100%" }}>
              <div className="level is-mobile is-marginless">
                <div className="level-left">
                  <p className="title is-5 has-text-dark">
                    {messages.labels.enterCardDetails[locale]}
                  </p>
                </div>
                <div className="level-right">
                  <PoweredByStripeLogo />
                </div>
              </div>
              <label className="label is-small">
                {messages.labels.cardNumber[locale]}
                <StripeElementWrapper>
                  <CardNumberElement
                    onChange={(e) => handleChange(e, "cardNumber")}
                    options={createOptions()}
                    onReady={(ele) => handleReady(ele)}
                  />
                </StripeElementWrapper>
              </label>
              <label className="label is-small">
                {messages.labels.cardExpiry[locale]}
                <StripeElementWrapper>
                  <CardExpiryElement
                    onChange={(e) => handleChange(e, "cardExpiry")}
                    options={createOptions()}
                    onReady={(ele) => handleReady(ele)}
                  />
                </StripeElementWrapper>
              </label>
              <label className="label is-small">
                {messages.labels.cardCVC[locale]}
                <StripeElementWrapper>
                  <CardCvcElement
                    onChange={(e) => handleChange(e, "cardCVC")}
                    options={createOptions()}
                    onReady={(ele) => handleReady(ele)}
                  />
                </StripeElementWrapper>
              </label>
              {validation.error && (
                <p className="is-danger help">{validation.message}</p>
              )}
            </div>
          </div>
          <div className="column is-6-desktop is-12-mobile is-flex-desktop">
            <div
              className="box"
              style={{
                width: "100%",
              }}
            >
              <Buttons>
                <Button
                  className="is-link is-fullwidth"
                  onClick={submit}
                  disabled={
                    validation.error ||
                    Object.keys(cardInputsCompleted).some(
                      (k) => !cardInputsCompleted[k]
                    )
                  }
                  group="stripe"
                  name="updateCard"
                />
                <Button
                  className="is-fullwidth"
                  onClick={handleCancel}
                  group="common"
                  name="cancel"
                />
              </Buttons>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.containers.subscription.stripe,
  };
};

export default connect(mapStateToProps, actions)(UpdateCard);
